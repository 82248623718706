<template>
  <Dialog
    class="dialog-add-quota"
    :name="name"
    :show-close="!isEditQuota"
    width="600px"
  >
    <template #title>
      <div>{{ getTitle }}</div>
    </template>

    <CreateQuotaForm
      :quota="quota"
      :can-edit="!wasImproved"
      class="dialog-add-quota__body"
      @changed="setImproveMode"
    />
    <ShareQuotaForm
      v-if="isEditQuota"
      :quota="quota"
      :can-improve="!wasEdited"
      @improved="setEditMode"
    />
  </Dialog>
</template>

<script>
import CreateQuotaForm from '@/views/home/components/dialogs/dialogAddQuota/components/CreateQuotaForm'
import Dialog from '@/UI/dialog/Dialog'
import ShareQuotaForm from '@/views/home/components/dialogs/dialogAddQuota/components/ShareQuotaForm.vue'

import { DIALOG_ADD_QUOTA } from '@/constants/dialogs'
export default {
  name: 'DialogAddQuota',
  components: {
    Dialog,
    CreateQuotaForm,
    ShareQuotaForm,
  },
  data() {
    return {
      name: DIALOG_ADD_QUOTA,
      wasEdited: false,
      wasImproved: false,
    }
  },
  computed: {
    quota() {
      return this.getDialog(this.name).data
    },
    isEditQuota() {
      return Boolean(this.quota?.isEdit)
    },
    getTitle() {
      return this.isEditQuota ? 'Обновление квоты' : 'Добавление квоты'
    },
  },
  methods: {
    // запрещаем распределение, если были изменения в редактировании, и наоборот
    setImproveMode(value) {
      this.wasEdited = value
    },
    setEditMode(value) {
      this.wasImproved = value
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-add-quota
  ::v-deep .el-dialog__body
    padding: 0 !important
  &__body
    padding: 20px 16px 16px
</style>
